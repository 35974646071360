<template>
  <div class="about">
    <!-- <div class="banner-aboutus"></div> -->
    <aboutus />
    <!-- <div class="abtu">
      <div>
        <p class="title">公司介绍篇</p>
        <div class="content">
          <p>
            深圳市医时空健康养生服务有限公司(简称"医时空")，成立于2017年，是一家以云计算和人工智能为中心,专注于中医的互联网公司。
          </p>
          <p>医时空秉持"开放、共赢"的理念，推动中医复兴，助力中国梦。</p>
        </div>
      </div>
      <div>
        <p class="title">公司产品篇</p>
        <div class="content">
          <p>医时空产品"大医云系统"、"聚合中医"。</p>
          <p>
            "大医云系统"为中医馆提供中医特色的管理系统、运营方案等服务，帮助医馆提高客户服务体验，减少人力成本，增加医馆营收。
          </p>
          <p>
            "聚合中医"为中医需求者提供寻医问诊、中医自诊、中医电台，中医视频、中医知识等推荐搜索服务。
          </p>
        </div>
      </div>
      <div>
        <p class="title">加入我们</p>
        <div class="joinus">
          <div style="background-color: #edd4cb">
            <div><img src="~assets/img/about/aboutus_phone.png" alt="" /></div>
            <p>联系电话</p>
            <p>0755-36850114</p>
          </div>
          <div style="background-color: #dae3e1">
            <div><img src="~assets/img/about/aboutus_mail.png" alt="" /></div>
            <p>联系邮箱</p>
            <p>hr@sktcm.com</p>
          </div>
          <div style="background-color: #eae5dd">
            <div><img src="~assets/img/about/aboutus_weixin.png" alt="" /></div>
            <p>微信小助手</p>
            <p>yishikongkefu</p>
          </div>
          <div style="background-color: #efe7d3">
            <div><img src="~assets/img/about/aboutus_qq.png" alt="" /></div>
            <p>客服QQ</p>
            <p>1571444251 <br> 3210706890</p>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import Aboutus from '../jiameng/childrenCpn/Aboutus.vue';
export default {
  name: "About",
  components: { 
    Aboutus 
  }
};
</script>

<style scoped>
.about {
  text-align: center;
  width: 100%;
  min-width: 1220px;
}

.banner-aboutus {
  width: 100%;
  height: 207px;
  background: url(~assets/img/about/aboutus_banner.jpg) no-repeat;
  background-size: 100% 100%;
}

.abtu {
  box-sizing: border-box;
  padding: 0 10%;
  width: 100%;
  margin: 0 auto;
}

.abtu > div {
  margin: 150px 0;
  text-align: left;
}

.title {
  font-size: 32px;
}

.content {
  margin-top: 40px;
}

.content p {
  text-indent: 62px;
  /* margin-top: 40px; */
  color: #999999;
  font-size: 28px;
  letter-spacing: 3px;
  line-height: 45px;
}

.joinus {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  margin-bottom: 200px;
  width: 100%;
}

.joinus > div {
  width: 253px;
  height: 253px;
  padding: 35px;
  color: #686865;
  text-align: center;
  font-size: 18px;
  line-height: 25px;
}

.joinus > div p {
  margin-top: 30px;
}
</style>
